import "./widget.scss"
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import ListAltIcon from '@mui/icons-material/ListAlt';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';


function Widget({ type }) {
    let data;

    //temporary
    const amount = 100;
    const diff = 20;

    switch(type){
      case "user":
        data = {
          title:"USERS",
          isMoney: false,
          link: "See all users",
          icon: <Person2OutlinedIcon className="icon" style={{color:"crimson", backgroundColor:"rgba(255, 0, 0,0.2)"}}/>,
        };
        break;
        case "order":
        data = {
          title:"ORDERS",
          isMoney: false,
          link: "View all orders",
          icon: <ListAltIcon className="icon" style={{color:"goldenrod", backgroundColor:"rgba(218, 165, 32,0.2)"}}/>,
        };
        break;
        case "earning":
        data = {
          title:"EARNINGS",
          isMoney: true,
          link: "View net earnings",
          icon: <MonetizationOnOutlinedIcon className="icon" style={{color:"green", backgroundColor:"rgba(0, 128, 0,0.2)"}}/>,
        };
        break;
        case "balance":
        data = {
          title:"BALANCE",
          isMoney: true,
          link: "See details",
          icon: <AccountBalanceOutlinedIcon className="icon" style={{color:"purple", backgroundColor:"rgba(128, 0, 128,0.2)"}}/>,
        };
        break;  
        dafault:
        break;
    }

        
    
  return (
    <div className="widget">
        <div className="left">
            <span className="title">{data.title}</span>
            <span className="counter">{data.isMoney && "$"} {amount}</span>
            <span className="link">{data.link}</span>
        </div>
        <div className="right">
            <div className="percentage positive">
                <KeyboardArrowUpOutlinedIcon/>
                20%
            </div>
            {data.icon}
        </div>
    </div>
  )
}

export default Widget